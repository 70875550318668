@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Lora:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #003994;
  --color-dark-primary: #002d75;
  --color-light-primary: #1f5bbb;
  --color-surface-primary: #6aa3e7;
  --color-secondary: #f79e0e;

  --color-yellow: #fec24d;
  --color-green: #50a831;
  --color-orange: #faa74a;
  --color-orange-dark: #f68a50;
  --color-blue: #00a3df;
  --color-cyan: #00b7ba;
  --color-red: #f16047;
  --color-violet: #f0649c;

  --color-sky-100: #e0f2fe;
  --color-sky-300: #7dd3fc;
  --color-sky-500: #0ea5e9;

  --color-dark: #262626;
  --color-gray: #717171;
  --color-graylight: #f8f8f8;
  --color-dark-lighten: #3c446f;
  // --color-dark-lighten-2: #495284;
  --color-dark-lighten-2: #8999ff;

  --color-gradient-primary: linear-gradient(
    90deg,
    #285daa 0%,
    #2759a3 24%,
    #244e91 58%,
    #203c74 97%,
    #203c73 99%
  );
}

// Status Color
.bg-draft {
  background-color: #f43f5e;
}
.bg-posent {
  background-color: #f59e0b;
}
.bg-shipping {
  background-color: #fcd34d;
}
.bg-arrived {
  background-color: #4fb0c6;
}
.bg-received {
  background-color: #4f86c6;
}
.bg-completed {
  background-color: #22c55e;
}

input {
  outline: none;
}

input[type="date"],
input[type="month"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 1.9rem;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input:disabled {
  -webkit-text-fill-color: #64748b;
  opacity: 1; /* required on iOS */
}

textarea {
  outline: none;
  resize: none;
}
textarea:disabled {
  -webkit-text-fill-color: #64748b;
  opacity: 1; /* required on iOS */
}

input[type="checkbox"] {
  width: auto;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.25); /* IE */
  -moz-transform: scale(1.25); /* FF */
  -webkit-transform: scale(1.25); /* Safari and Chrome */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
}

select {
  min-height: 1.9rem;
  background-color: transparent;
  opacity: 1; /* required on iOS */
}

select:disabled {
  color: #475569;
  -webkit-text-fill-color: #475569;
  opacity: 1; /* required on iOS */
}

input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}

input[type="color"]::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.textColor-disabled {
  color: #64748b !important;
  -webkit-text-fill-color: #64748b !important;
  opacity: 1; /* required on iOS */
}

.btnColor-disabled {
  color: var(--color-light-primary) !important;
  -webkit-text-fill-color: var(--color-light-primary) !important;
  opacity: 1; /* required on iOS */
}

.btnColor-disabled:hover {
  color: var(--color-primary) !important;
  -webkit-text-fill-color: var(--color-primary) !important;
  opacity: 1; /* required on iOS */
}

.scrollbar-custom {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 10px;
  }
}

.scrollbar-horizontal {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    z-index: 500;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6d6d6 !important;
    border-radius: 10px;
  }
}

.scrollbar-sidebar {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #6d78b1;
    border-radius: 10px;
  }
}

.shadow-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.shadow-card-hover:hover {
  box-shadow: rgb(0 0 0 / 26%) 0px 5px 12px;
}

.bg-gradient-primary {
  background: var(--color-gradient-primary);
}

.td-index {
  width: 30px !important;
  min-width: 30px !important;
}

.td-40 {
  width: 40% !important;
  min-width: 40% !important;
}

.whitespace-wrap-important {
  white-space: pre-wrap !important;
}

// FORM INPUT STYLE
.inputText {
  border-bottom: 2px solid #ced5dd;
  background: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
  border-radius: 0;
}

.inputText::placeholder,
.inputText::-moz-placeholder,
.inputText::-webkit-input-placeholder {
  color: #334155;
}
.inputText:disabled {
  color: #64748b;
  -webkit-text-fill-color: #64748b;
  opacity: 1; /* required on iOS */
  border-color: #e2e8f0;
  background-color: #f1f5f9;
}

.inputText-empty {
  border-bottom: 2px solid #ced5dd;
  background: #f1f5f9;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
}

.inputText-empty::placeholder,
.inputText-empty::-moz-placeholder,
.inputText-empty::-webkit-input-placeholder {
  color: #334155;
}
.inputText-empty:disabled {
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}

.inputText-edit {
  border-bottom: 2px solid #ced5dd;
  background: #f1f5f9;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
}
.inputText-edit::placeholder,
.inputText-edit::-moz-placeholder,
.inputText-edit::-webkit-input-placeholder {
  color: #334155;
}

.inputText-view {
  border-bottom: 2px solid #ced5dd;
  background: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}

.inputText-view:disabled {
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}

// FORM INPUT DISABLED STYLE NEW
.inputText-disabled {
  border-bottom: 2px solid #e2e8f0;
  background-color: #f1f5f9;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}
.inputText-disabled:disabled {
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}

// FORM INPUT DISABLED STYLE OLD
.inputTextDisabled {
  border-bottom: 2px solid #64748b;
  background: #e2e8f0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
  color: #334155;
  border-radius: 2px;
}

.inputText-hidden {
  border-bottom: 2px solid transparent;
  background: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
}

// FORM INPUT STYLE
.inputTextarea {
  background: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
}

.inputTextarea::placeholder,
.inputTextarea::-moz-placeholder,
.inputTextarea::-webkit-input-placeholder {
  color: #334155;
}
.inputTextarea:disabled {
  color: #64748b;
  -webkit-text-fill-color: #64748b;
  opacity: 1; /* required on iOS */
  border-color: #e2e8f0;
  background-color: #f1f5f9;
}

// FORM INPUT STYLE
.inputTextarea-empty {
  background: #ffffff;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
  border: 1px solid var(--color-sky-500);
}

.inputTextarea-empty::placeholder,
.inputTextarea-empty::-moz-placeholder,
.inputTextarea-empty::-webkit-input-placeholder {
  color: #334155;
}

.inputTextarea-edit {
  background: #f1f5f9;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2px 8px;
}
.inputTextarea-edit::placeholder,
.inputTextarea-edit::-moz-placeholder,
.inputTextarea-edit::-webkit-input-placeholder {
  color: #334155;
}

.inputTextarea-view {
  background: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 8px;
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}
.inputTextarea-view:disabled {
  color: #334155;
  -webkit-text-fill-color: #334155;
  opacity: 1; /* required on iOS */
}

// Table Cell CSS Styling
.table-cell-border {
  th,
  td {
    border-right: 1px solid #cbd5e1;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }
}

// Table Accordion
.tr-head-accordion {
  th {
    min-width: 50px;
    max-width: 360px;
    padding: 12px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.tr-body-accordion {
  td {
    min-width: 50px;
    max-width: 360px;
    padding: 12px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.printText {
  border-bottom: 1px solid #000000;
  background: transparent;
  padding: 2px 4px;
}

.customModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;
  justify-content: center;
  align-items: center;

  &-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.barFilter {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;
  justify-content: end;
  align-items: end;
  z-index: 10;

  &-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.custSelect {
  div {
    padding: 0.125rem 0.1rem 0.125rem 0.625rem;
    display: flex;
    align-items: center;
  }
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.absolute-x-center {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.absolute-y-center {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.absolute-x-center-rotate {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
}

/* Glassmorphism card effect */
.glassmorphism {
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  background-color: rgba(239, 249, 255, 0.75);
  border: 1px solid rgba(209, 213, 219, 0.3);
}
