.table__tbody {
  tr {
    td {
      min-width: 50px;
      max-width: 360px;
      padding: 12px 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 640px) {
  .table__tbody {
    tr {
      td {
        min-width: 80px;
        max-width: 360px;
        padding: 12px 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
