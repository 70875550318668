.table__thead {
  tr {
    th {
      padding: 12px 8px;
      min-width: 50px;
      max-width: 360px;
      font-size: 13px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 640px) {
  .table__thead {
    tr {
      th {
        padding: 12px 8px;
        min-width: 80px;
        max-width: 360px;
        font-size: 13px;
  
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}