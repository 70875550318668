.print__tbody {
  tr {
    border-color: #000000;

    td {
      min-width: 60px;
      max-width: 320px;
      padding: 12px 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #000000;
    }

    td:last-child {
      border-right: 0;
    }
  }
}

.print__tbody__md {
  tr {
    border-color: #000000;

    td {
      min-width: 60px;
      max-width: 320px;
      padding: 8px 6px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #000000;
    }

    td:last-child {
      border-right: 0;
    }
  }
}

.print__tbody__sm {
  tr {
    border-color: #000000;

    td {
      min-width: 60px;
      max-width: 320px;
      padding: 4px 6px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #000000;
    }

    td:last-child {
      border-right: 0;
    }
  }
}

.print__thead {
  tr {
    border-color: #000000;

    th {
      padding: 12px 8px;
      min-width: 60px;
      max-width: 320px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #000000;
    }

    th:last-child {
      border-right: 0;
    }
  }
}

.print__thead__sm {
  tr {
    border-color: #000000;

    th {
      padding: 8px 6px;
      min-width: 60px;
      max-width: 320px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #000000;
    }

    th:last-child {
      border-right: 0;
    }
  }
}

.underline-padding {
  p {
    strong {
      u {
        margin-bottom: 8px;
        display: flex;
      }
    }
  }
}
